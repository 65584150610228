import { ReactNode, useState } from "react";
import _ from "lodash";
import { useRouter } from "next/router";

import useSnackbar from "@hooks/use-snackbar";
import analytics from "@lib/analytics";
import { ArtifactType } from "@lib/shared-types";

import TrashIcon from "@components/Icons/TrashIcon";
import SmallModal from "@components/Modals/SmallModal";

type Props = {
  trigger?: ReactNode;
  show?: boolean;
  toggleShow?: (val: boolean) => void;
  onDelete: (val: void) => void | Promise<void>;
  onRecurringDelete?: (val: void) => void;
  onUndoDelete?: (val: string, id?: string) => void;
  undoStatus?: string;
  artifactType: ArtifactType;
  description?: string;
  disableRouteBack?: boolean;
  artifactId?: string;
  showSnackbar?: boolean;
};

const DeleteModal: React.FC<Props> = ({
  trigger,
  show,
  toggleShow,
  onDelete,
  onRecurringDelete,
  onUndoDelete,
  undoStatus,
  artifactType,
  description,
  disableRouteBack = false,
  artifactId,
  showSnackbar = true,
}) => {
  const [actionLoading, setActionLoading] = useState<boolean>(false);
  const router = useRouter();
  const snackbar = useSnackbar();

  const track = (event: string) => {
    analytics.track({
      event,
      properties: { artifactType },
    });
  };

  return (
    <SmallModal
      trigger={trigger}
      show={show}
      toggleShow={toggleShow}
      icon={TrashIcon}
      iconColor="peach"
      title="Confirm deletion?"
      description={
        description
          ? description
          : !onRecurringDelete
          ? `This will permanently delete this ${artifactType} from everywhere.`
          : artifactType === "appointment"
          ? "This will remove the appointment from your calendar and notify your client by email."
          : `Do you want to delete only this ${artifactType}, or this and all future recurring ${artifactType}s?`
      }
      onActionLoading={actionLoading}
      onAction={async () => {
        setActionLoading(true);
        try {
          await onDelete();
          track("delete_modal_deleted");
          if (artifactType === "event")
            // heap event name used by events report
            track("group-session_coach_event-removed");
          if (!disableRouteBack) router.back();

          if (showSnackbar) {
            snackbar.showMessage(
              `${_.capitalize(artifactType)} deleted`,
              `You've successfully deleted the ${artifactType}`,
              onUndoDelete && undoStatus ? "Undo" : "",
              onUndoDelete && undoStatus
                ? () => {
                    try {
                      onUndoDelete(undoStatus, artifactId);
                      track("delete_modal_undone");
                    } catch (e) {
                      track("delete_modal_undone_error");
                    }
                  }
                : undefined
            );
          }
        } catch (e) {
          track("delete_modal_deleted_error");
          if (artifactType === "event")
            // heap event name used by events report
            track("group-session_coach_event-removed-error");
          snackbar.showMessage(
            `Deleting ${artifactType} failed`,
            `There was an error deleting the ${artifactType}`,
            "",
            undefined
          );
        } finally {
          setActionLoading(false);
        }
      }}
      onActionText={
        !onRecurringDelete ? `Delete ${artifactType}` : `This ${artifactType}`
      }
      onSecondaryAction={
        onRecurringDelete
          ? () => {
              try {
                onRecurringDelete();
                track("delete_modal_recurring_deleted");
                if (!disableRouteBack) router.back();
                snackbar.showMessage(
                  `${_.capitalize(artifactType)}s deleted`,
                  `You've successfully deleted the ${artifactType}s`,
                  "",
                  undefined
                );
              } catch (e) {
                track("delete_modal_recurring_deleted_error");
                snackbar.showMessage(
                  `Deleting ${artifactType}s failed`,
                  `There was an error deleting the ${artifactType}s`,
                  "",
                  undefined
                );
              }
            }
          : undefined
      }
      onSecondaryActionText={
        onRecurringDelete && `This and following ${artifactType}s`
      }
      heapModalName="delete_modal"
    />
  );
};

export default DeleteModal;
